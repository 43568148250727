import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import VueApexCharts from 'vue-apexcharts'
import EasyCamera from 'easy-vue-camera'
import { VueKuzzle } from 'vue-plugin-kuzzle'

const kuzzleBackend = {
  backends: {
    local: {
      host: 'kz.plique.me',
      options: {
        port: 17512,
        ssl: true
      }
    },
    production: {
      host: 'kz.plique.me',
      options: {
        port: 17512,
        ssl: true
      }
    }
  }
}

Vue.use(VueKuzzle, kuzzleBackend)
Vue.use(VueApexCharts)
Vue.component('v-easy-camera', EasyCamera)

Vue.component('ApexChart', VueApexCharts)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
