import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    products: [],
    params: {}
  },
  getters: {
  },
  mutations: {
    setProducts (state, products) {
      state.products = products
    },
    setParams (state, params) {
      state.params = params
    }
  },
  actions: {
  },
  modules: {
  }
})
