<template>
  <div class="fill-height">
    <v-container v-if="displayHomeText">
      <v-row>
        <v-col cols="12">
          Les produits de soin et de beauté Base Beauté Bio, Fabriqués en
          France, concilient Naturalité et Performance, proposent le strict
          nécessaire à la peau, et révèlent sa beauté naturelle.
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn @click="openCamera" color="#3996A9" class="white--text">
            Prendre une photo</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <div v-else class="fill-height">
      <v-easy-camera
        class="reverse"
        v-if="cameraStarted"
        ref="camera"
        :fullscreen="fullscreen"
        :autoDetectMobile="autoDetectMobile"
        overlayMask="/path89.png"
        :visibleOverlay="true"
        v-model="picture"
        @close="close"
      >
      </v-easy-camera>
      <v-container v-else class="fill-height">
        <v-row class="picture-row">
          <v-col cols="12">
            <img width="100%" class="picture" :src="picture" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn @click="restart" color="#F38672"> recommencer </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn @click="confirm" color="#3996A9" class="white--text">
              valider
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      picture: null,
      autoDetectMobile: true,
      fullscreen: true,
      displayHomeText: true,
      cameraStarted: false
    }
  },
  computed: {
    cameraDisplayed () {
      return !this.displayHomeText && this.cameraStarted
    }
  },
  watch: {
    cameraDisplayed (value) {
      if (value) {
        setTimeout(() => {
          console.log(
            (this.$refs.camera.$refs.fullscreenCameraRef.visibleMask = true)
          )
        }, 500)
      }
    },
    picture (value) {
      if (value) {
        this.$refs.camera.stop()
        this.cameraStarted = false
        this.autoDetectMobile = false
        this.fullscreen = false
      } else {
        this.autoDetectMobile = true
        this.fullscreen = true
      }
    }
  },
  methods: {
    async confirm () {
      let requestId
      try {
        const result = await this.$kuzzle.query({
          controller: 'poc',
          action: 'uploadPicture',
          body: {
            picture: this.picture
          }
        })
        requestId = result.result.requestId
      } catch (error) {
        console.error(error)
      }
      this.$router.push({ name: 'result', params: { requestId } })
    },
    async restart () {
      this.picture = null
      this.cameraStarted = true
      await Vue.nextTick()
      this.$refs.camera.start()
    },
    close () {
      this.$refs.camera.stop()
      this.cameraStarted = false
      this.displayHomeText = true
    },
    async openCamera () {
      this.displayHomeText = false
      this.cameraStarted = true
      await Vue.nextTick()
      this.$refs.camera.start()
    }
  }
}
</script>
<style scoped>
.reverse {
  transform: scaleX(-1);
}
.picture {
  object-fit: none;
  height: 100%;
  transform: scaleX(-1);
}
.picture-row {
  height: 80%;
}
</style>

<style lang="scss">
.fullscreen-camera .camera-stack .video-wrapper .overlay-mask.visible-overlay {
  box-shadow: none !important;
  img {
    height: 140% !important;
  }
}
</style>
