<template>
  <v-app>
    <v-app-bar app color="#F38671" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.shopify.com/s/files/1/0348/8379/7131/files/LOGO_BASE_Blanc_400x100_410x.png?v=1585295449"
          transition="scale-transition"
          width="120"
        />
      </div>
    </v-app-bar>
    <v-main>
      <router-view v-if="!loading" />
      <v-container v-else class="fill-height">
        <v-row>
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="70"
              :width="7"
              indeterminate
              color="#F38671"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      loading: true
    }
  },
  methods: {
    async connectKuzzle () {
      try {
        await this.$kuzzle.connect()
        this.loading = false
      } catch (error) {
        console.error(error.message)
      }
    }
  },
  async mounted () {
    this.connectKuzzle()
  }
}
</script>
